// project-imports
import applications from './applications';
import widget from './widget';
import formsTables from './forms-tables';
import chartsMap from './charts-map';
import support from './support';
import pages from './pages';
import clinic from './clinic';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [applications,]

  // formsTables, chartsMap, pages, support
};

export const clinicMenuItems = {
  items: [clinic,]
}

export default menuItems;
